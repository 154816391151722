import ContainerMain from "pages/common/ContainerMain"
import { useFormatter } from "helpers/i18n"
import "./PressRelease.scss"
import banner from "images/Company/About/pressrelease.svg"
import EntrepreneurAward from "images/Company/About/EntrepreneurAward.png"
import BestManagedCompaniesInVietnam2024 from "images/Company/About/BestManagedCompaniesInVietnam2024.png"

const MediaItem = ({ media, img, time, title, subtitle, link }) => {
  const { __, Link } = useFormatter()
  return (
    <ContainerMain>
      <div className="jsLQNqcNGk">
        <div className="MfMKETAyeK">
          <div className="YDUqZVaEXx-press-release">
            <img src={img} alt="" className="REJCdcJGLC-press-release" />
          </div>
          <div className="EouhQVFgnk-press-release">
            <div className="xoMfeDjLfZ">
              <div className="zTXlXBYbxv">{media}</div>
              <div className="nXnWOxmwOU">{time}</div>
            </div>
            <div className="fXQJNrtARH">{title}</div>
            <div className="tAgywSKksr">{subtitle}</div>
            <Link to={link} className="btn-learn-more background-red">
              {__({ defaultMessage: "Learn more" })}
            </Link>
          </div>
        </div>
      </div>
    </ContainerMain>
  )
}

export default function Tabnew() {
  const { __, Link } = useFormatter()
  return (
    <>
      <div className="VMUOqxQmhV">
        <div className="AoJWRgRSJU">
          <div className="section-title svoVqdrGDg">
            {__({
              defaultMessage: "Press Release",
            })}
          </div>
        </div>
        <div className="wLZyEorIXA">
          <MediaItem
            media="Media"
            time="18:00 29/08/2024"
            img={BestManagedCompaniesInVietnam2024}
            title={__({
              defaultMessage:
                "OnPoint is a winner of Vietnam's Best Managed Companies for 2024",
            })}
            subtitle={__({
              defaultMessage:
                "VIETNAM, August 19, 2024 – OnPoint has been presented with the prestigious 2024 Vietnam’s Best Managed Companies award for its industry-leading performance, excellent business practices and sustained growth.",
            })}
            link="/media/best-managed-companies/"
          />

          <MediaItem
            media="Media"
            time="18:00 24/06/2022"
            img={banner}
            title={__({
              defaultMessage:
                "OnPoint closes the largest Series B round for a SEA e-commerce enabler led by SeaTown Holdings International",
            })}
            subtitle={__({
              defaultMessage:
                "OnPoint (“the Company”), the leading e-commerce enabler in Vietnam, today announced the signing of its Series B fundraising round with SeaTown Private Capital Master Fund, a fund managed by SeaTown Holdings International (“SeaTown”), an indirect wholly owned subsidiary of Temasek Holdings...",
            })}
            link="/media/press-release/"
          />

          <MediaItem
            media="Media"
            time="18:00 27/12/2022"
            img={EntrepreneurAward}
            title={__({
              defaultMessage: `ONPOINT'S FOUNDER AND CEO RECEIVED "THE ENTREPRENEUR AWARD OF HO CHI MINH CITY"`,
            })}
            subtitle={__({
              defaultMessage: `We are delighted to announce that Tran Vu Quang, Founder and CEO of OnPoint, has received "The Entrepreneur Award of Ho Chi Minh City" organized 11th time by the Standing Committee of Ho Chi Minh City Youth Union and Young Entrepreneurs Association of Ho Chi Minh City.`,
            })}
            link="/media/entrepreneur-award/"
          />
        </div>
      </div>
    </>
  )
}
